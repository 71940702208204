import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import Logout from './components/Logout';
import Login from './pages/login/Login';
import CourseDetail from './pages/course/course.detail';
import signUp from './pages/login/signup';
import orderPreview from './pages/order/previewOrder';
import ordersave from './pages/order/saveOrder';
import Home from './pages/home/Home';
import Contact from './pages/contact/contact';
import UserProfile from './pages/userProfile/user.profile';
import CourseHome from './pages/course/Course';
import CourseAvailable from './pages/availableCourse/availableCourse';
import Pg from './pages/pg/pg';
// import Checkout from './pages/Payment/payment';
import ForgetPassword from './pages/login/forget.password';
import ResetPassword from './pages/login/reset.password';
import UserCourse from './pages/userProfile/user.course';
import AssignmentDetail from './pages/userProfile/AssignmentDetail';
import PreviewCourse from './pages/coursePreview/previewcourse.detail';
import ForgetPasswordConfirmation from './pages/login/forget.password.confirmation';
import Faq from './pages/faq/faq';
import Blogs from './pages/blogs/blogs';
import BlogDetail from './pages/blogs/blogDetail';
import NewsDetail from './pages/blogs/newsDetail';
import AnnouncementDetail from './pages/blogs/announcementDetail';
import Header from './pages/header/header';
import Footer from './pages/footer/footer';
import { Error404 } from './pages/ErrorPage/Error404';
import EventMetting from './pages/Eventmetting/EventMetting';

import UtilsService from './services/utilservice';
import CourseResult from './pages/userProfile/CourseResult';
import { FeedBackForm } from './pages/userProfile/FeedBackForm';
import PasswordChanged from './pages/userProfile/PasswordChanged';
import Testimonial from './pages/userProfile/testimonial';

import Form from './pages/forms/form';
import MyCourse from './pages/userProfile/mycourse';
import { Helmet } from 'react-helmet';
import Setting from './services/settingService';

import lang from './language';
const language = localStorage.getItem('language');
if (language == null) {
  window.selLang = 'english';
} else {
  window.selLang = language;
}

window.language = lang;
export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isLogin: false,
      userdata: null,
      access: true,
      site_title: '',
      favicon: '',
      meta_keywords: '',
      meta_description: '',
    };
    if (window.user) {
      this.state.isLogin = true;
    }
    // this.handlerLogin = this.handlerLogin.bind(this);
    this.handlerUserName = this.handlerUserName.bind(this);
  }

  componentDidMount() {
    this.checkIp();
    this.settingServ = new Setting();
    this.settingServ.listSetting(0, 1000000).then(
      (response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].name === 'site_title') {
            this.setState({ site_title: response.data[i].value });
          }
          if (response.data[i].name === 'favicon_icon') {
            this.setState({ favicon: response.data[i].value });
          }
          if (response.data[i].name === 'meta_description') {
            this.setState({ meta_description: response.data[i].value });
          }
          if (response.data[i].name === 'meta_keywords') {
            this.setState({ meta_keywords: response.data[i].value });
          }
        }
      },
      (error) => {
        this.setState({ settingList: [] });
      }
    );
  }

  handlerLogin(islogin) {
    this.setState({ isLogin: islogin });
  }
  handlerUserName(res) {
    let user = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      let token = user.token;
      let newUsr = { ...res, token };
      window.user = newUsr;
      localStorage.setItem('user', JSON.stringify(newUsr));
    }
    this.setState({ userdata: res.data });
  }
  checkIp() {
    UtilsService.CheckIp()
      .then((res) => {
        this.setState({ access: true });
      })
      .catch((err) => {
        this.setState({ access: false });
      });
  }

  render() {
    //console.log(window.location.pathname,'pathname')
    return (
      <div className='App'>
        <Helmet>
          <title>{this.state.site_title}</title>
          <meta name='keywords' content={this.state.meta_keywords} />
          <meta name='description' content={this.state.meta_description} />
          <link rel='icon' href={this.state.favicon} />
        </Helmet>
        {this.state.access ? (
          <div>
            <HashRouter>
              <section className='main-content'>
                {window.location.pathname === '/teacher/course' ? null : (
                  <Header isLogin={this.state.isLogin} updateUserData={this.state.userdata} />
                )}

                <Switch>
                  <Route
                    path='/login'
                    render={(props) => (
                      <Login
                        handlerLoginParent={this.handlerLogin.bind(this)}
                        handlerUserName={this.handlerUserName.bind(this)}
                        {...props}
                      />
                    )}
                  />

                  {/* <Route exact path='/' render={(props) => <Home {...props} />} /> */}
                  <Route exact path='/' render={(props) =>
                    <Login
                      handlerLoginParent={this.handlerLogin.bind(this)}
                      handlerUserName={this.handlerUserName.bind(this)}
                      {...props}
                    />} />
                  <Route
                    path='/logout'
                    render={(props) => <Logout {...props} handlerLoginParent={this.handlerLogin.bind(this)} />}
                  />
                  <Route exact path='/details/:id' component={CourseDetail} />
                  <Route exact path='/teacher/course/:id/:user/:userid' component={PreviewCourse} />
                  {/* <Route exact path='/available/course' component={CourseAvailable} /> */}
                  <Route exact path='/course' component={CourseHome} />
                  <Route exact path='/course/:searchTxt' component={CourseHome} />
                  <Route exact path='/course/category/:id' component={CourseHome} />
                  <Route path='/contact' component={Contact} />
                  <Route path='/signup' component={signUp} />
                  <PrivateRoute exact path='/order/:id' component={orderPreview} />
                  <Route exact path='/order/tk/:token' component={orderPreview} />
                  <PrivateRoute exact path='/order/save/:id' component={ordersave} />
                  <PrivateRoute exact
                    path='/available/course'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <PrivateRoute exact
                    path='/user/profile'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <Route
                    path='/user/order'
                    render={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <PrivateRoute exact
                    path='/user-profile'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <PrivateRoute exect
                    path='/user-course'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <PrivateRoute exact
                    path='/user-expired-course'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <PrivateRoute exact
                    path='/user-live-session'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <PrivateRoute exact
                    path='/user-message'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <PrivateRoute exact
                    path='/user-notification'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <PrivateRoute exact
                    path='/pending-assignment'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <PrivateRoute exact
                    path='/complete-assignment'
                    component={(props) => <UserProfile handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <Route path='/pg/:id' component={Pg} />
                  <Route path='/forgetpassword' component={ForgetPassword} />
                  <Route exact path='/reset/:token' component={ResetPassword} />
                  <Route exact path='/forgetpasswordconfirm' component={ForgetPasswordConfirmation} />
                  <Route exact path='/user/course/:id/:user' component={UserCourse} />
                  <Route exact path='/assignment/:courseid/:assignmentid' component={AssignmentDetail} />
                  <Route exact path='/faq' component={Faq} />
                  <Route path='/blogs' component={Blogs} />
                  <Route
                    path='/events'
                    render={(props) => <Blogs handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <Route
                    path='/announcements'
                    render={(props) => <Blogs handlerUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                  <Route path='/blog/:id' component={BlogDetail} />
                  <Route path='/news/:id' component={NewsDetail} />
                  <Route path='/announcement/:id' component={AnnouncementDetail} />
                  <Route path='/course/result/:id' component={CourseResult} />
                  <Route path='/feedback/:id' component={FeedBackForm} />
                  <Route path='/eventmeeting/:meetingId/:name' component={EventMetting} />
                  <Route path='/testimonial/:id' component={Testimonial} />
                  <Route path='/form/:formId/:courseId' component={Form} />
                  <Route path='/user-course-form/' component={MyCourse} />

                  <Route
                    path='/change-password'
                    render={(props) => <PasswordChanged handleUserName={this.handlerUserName.bind(this)} {...props} />}
                  />
                </Switch>

                <Footer />
              </section>
            </HashRouter>
          </div>
        ) : (
          <HashRouter>
            <Route path='*' component={Error404} />
          </HashRouter>
        )}
      </div>
    );
  }
}
