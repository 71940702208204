const lang = {
  english: {
    Home: 'Home',
    Courses: 'Courses',
    'About Us': 'About Us',
    Contact: 'Contact',
    'Contact Us': 'Contact Us',
    'News Announcements And Events': 'News Announcements And Events',
    News: 'News',
    Welcome: 'Welcome',
    Login: 'Login',
    'Sign Up': 'Sign Up',
    Blogs: 'Blogs',
    Email: 'Email',
    'The webinar has been full': 'The webinar has been full',
    'Event not yet started': 'Event not yet started',
    Address: 'Address',
    'Copyright 2019. All Rights Reserved by': 'Copyright 2019. All Rights Reserved by',
    'Disclaimers & Copyright': 'Disclaimers & Copyright',
    'Terms And Condition': 'Terms And Condition',
    FAQs: 'FAQs',
    Author: 'Author',
    'Course Duration': 'Course Duration',
    'List Of Course Sections': 'List Of Course Sections',
    'Privacy Policy': 'Privacy Policy',
    Office: 'Office',
    'No Record Found': 'No Record Found',
    'Courses Category': 'Courses Category',
    'Please send us a message and we will get back to you soon.':
      'Please send us a message and we will get back to you soon.',
    Announcements: 'Announcements',
    Events: 'Events',
    'Error 404': 'Error 404',
    'Unfortunately site content is not avilable in your country. Please wait till the time services get started in your region.':
      'Unfortunately site content is not avilable in your country. Please wait till the time services get started in your region.',
    'Frequently asked questions': 'Frequently asked questions',
    Links: 'Links',
    'Forgot Password': 'Forgot Password',
    'You should soon receive an email allowing you to reset your password':
      'You should soon receive an email allowing you to reset your password',
    "Please make sure to check your spam and trash if you can't find the email":
      "Please make sure to check your spam and trash if you can't find the email",
    'Forgot Password!': 'Forgot Password!',
    'Reset Password': 'Reset Password',
    'login to your registered account!': 'login to your registered account!',
    'Remember Me': 'Remember Me',
    Password: 'Password',
    'Not a member yet?': 'Not a member yet?',
    'Register now': 'Register now',
    'New Password': 'New Password',
    'Confirm Password': 'Confirm Password',
    submit: 'submit',
    Login: 'Login',
    'Log Out': 'Log Out',
    'Register a new account': 'Register a new account',
    'Title*': 'Title*',
    'First Name*': 'First Name*',
    'Last Name*': 'Last Name*',
    'City*': 'City*',
    'Address Line 1*': 'Address Line 1*',
    'Address 2': 'Address 2',
    'Address 3': 'Address 3',
    'ZipCode*': 'ZipCode*',
    'State*': 'State*',
    'Country*': 'Country*',
    'Date Of Birth': 'Date Of Birth',
    Gender: 'Gender',
    Race: 'Race',
    Religion: 'Religion',
    Income: 'Income',
    'Marital Status': 'Marital Status',
    Education: 'Education',
    Industry: 'Industry',
    Employment: 'Employment',
    Ethnicity: 'Ethnicity',
    'Phone*': 'Phone*',
    'Password*': 'Password*',
    'Confirm Password*': 'Confirm Password*',
    'Receive Personalised': 'Receive Personalised',
    'Receive NewsLetter': 'Receive NewsLetter',
    'Are you a member?': 'Are you a member?',
    'Login now': 'Login now',
    'Sign up to the Publishing Clinic newsletter to receive the latest news and updates':
      'Sign up to the Publishing Clinic newsletter to receive the latest news and updates',
    'Enter Name': 'Enter Name',
    'Enter Email Address': 'Enter Email Address',
    Name: 'Name',
    'Order Date:': 'Order Date:',
    'Order Id:': 'Order Id:',
    'Course Name:': 'Course Name:',
    'Base Price': 'Base Price',
    'Tax Rate': 'Tax Rate',
    'Tax Type': 'Tax Type',
    'Tax Value': 'Tax Value',
    'Discount Value': 'Discount Value',
    'Final Price': 'Final Price',
    'Total:': 'Total:',
    CouponCode: 'CouponCode',
    'Apply Coupon': 'Apply Coupon',
    CheckOut: 'CheckOut',
    'Pay Rs.': 'Pay Rs.',
    'Popular Courses': 'Popular Courses',
    'Discourse assurance estimable applauded to so. Him everything melancholy uncommonly but solicitude inhabiting projection off. Connection stimulated estimating excellence an to impression.':
      'Discourse assurance estimable applauded to so. Him everything melancholy uncommonly but solicitude inhabiting projection off. Connection stimulated estimating excellence an to impression.',
    'Course Enrolled Successfull': 'You have successfully enrolled for this course.',
    'Thanks, we will notify you once course is available.': 'Thanks, we will notify you once course is available.',
    'Will you like to get notified once course is available.':
      'Will you like to get notified once course is available.',

    'Enter course name': 'Enter course name',
    'Search Courses': 'Search Courses',

    "Driver's list": "Driver's list",
    'Name/Company': 'Name/Company',
    Close: 'Close',
    'Students Feedback': 'Students Feedback',

    'Call Us': 'Call Us',
    'Email Us': 'Email Us',
    'Our Location': 'Our Location',
    'Phone number*': 'Phone number*',
    'Please enter your message here*': 'Please enter your message here*',
    'Send Message': 'Send Message',
    'Correct Answer:': 'Correct Answer:',
    Question: 'Question',
    Start: 'Start',
    'Total time left:': 'Total time left:',
    of: 'of',
    Preview: 'Preview',
    'Quiz Submitted': 'Quiz Submitted',
    'Show preview': 'Show preview',
    'Retry Again?': 'Retry Again?',
    'Result:': 'Result:',
    'You got': 'You got',
    'out of': 'out of',
    in: 'in',
    test: 'test',
    'Confirm Reset?': 'Confirm Reset?',
    'Are you sure want to retry?': 'Are you sure want to retry?',
    'it will erase all your previous result.': 'it will erase all your previous result.',
    'Show preview': 'Show preview',
    'Retry Again?': 'Retry Again?',
    'Confirm Reset?': 'Confirm Reset?',
    Yes: 'Yes',
    No: 'No',
    'Final result will be displayed here once teacher will review all your answer':
      'Final result will be displayed here once teacher will review all your answer',
    'Download Certificate': 'Download Certificate',
    'Quiz Name': 'Quiz Name',
    'Marks Obtained': 'Marks Obtained',
    Result: 'Result',
    'Thanks for choosing our course and hope you found it beneficial. Please spend a few minutes to gives us your thoughts about the course and any comments and suggestions. Thanks again for your time.':
      'Thanks for choosing our course and hope you found it beneficial. Please spend a few minutes to gives us your thoughts about the course and any comments and suggestions. Thanks again for your time.',
    Select: 'Select',
    Poor: 'Poor',
    Unsatisfactory: 'Unsatisfactory',
    Acceptable: 'Acceptable',
    Satisfactory: 'Satisfactory',
    Excellent: 'Excellent',
    Save: 'Save',
    'Thanks for your time and Best of Luck.': 'Thanks for your time and Best of Luck.',
    'Your browser does not support HTML video.': 'Your browser does not support HTML video.',
    'Add testimonial': 'Add testimonial',
    Feedback: 'Feedback',
    'Session taken on:': 'Session taken on:',
    Watch: 'Watch',
    'Events Details': 'Events Details',
    'Start Date': 'Start Date',
    'End Date': 'End Date',
    'Filter Teacher': 'Filter Teacher',
    Filter: 'Filter',
    'Reset Filters': 'Reset Filters',
    "Live Session Events Detail's": "Live Session Events Detail's",
    Event: 'Event',
    'Teacher Name': 'Teacher Name',
    Date: 'Date',
    Status: 'Status',
    'No Event Found': 'No Event Found',
    'Event List': 'Event List',
    'Course List': 'Course List',
    You: 'You',
    'Ask your Query...': 'Ask your Query...',
    'Type a message...': 'Type a message...',
    Send: 'Send',
    'Course Name': 'Course Name',
    'Choose Password': 'Choose Password',
    'Retype Password': 'Retype Password',
    'Old Password': 'Old Password',
    Update: 'Update',
    Summary: 'Summary',
    'Testimonial Details': 'Testimonial Details',
    Submit: 'Submit',
    'Live session start daily at :': 'Live session start daily at :',
    'End Date': 'End Date',
    'End Time': 'End Time',
    'Live session start weekely on :': 'Live session start weekely on :',
    'Live session start on these following dates:': 'Live session start on these following dates:',
    loading: 'loading',
    'Add testimonial': 'Add testimonial',
    Download: 'Download',
    Feedback: 'Feedback',
    'Add testimonial': 'Add testimonial',
    'Your Course Will Be Avaliable Soon.': 'Your Course Will Be Avaliable Soon.',
    download: 'download',
    Score: 'Score',
    'Expire On': 'Expire On',
    'No courses linked to your account': 'No courses linked to your account',
    'please click here to redirect': 'please click here to redirect',
    'please click here to redirect to course page': 'please click here to redirect to course page',
    Orders: 'Orders',
    'Edit Profile': 'Edit Profile',
    'My Courses': 'My Courses',
    Notifications: 'Notifications',
    Messages: 'Messages',
    'Live session details': 'Live session details',
    phone: 'phone',
    'Delete Account': 'Delete Account',
    Order: 'Order',
    'Course Name': 'Course Name',
    'Purchase Date': 'Purchase Date',
    Price: 'Price',
    Invoice: 'Invoice',
    'No order placed till now  please enrol yourself to the course.':
      'No order placed till now  please enrol yourself to the course.',
    'Change Password': 'Change Password',
    'Title*': 'Title*',
    Dr: 'Dr',
    Mr: 'Mr',
    Mrs: 'Mrs',
    Miss: 'Miss',
    Ms: 'Ms',
    'Update Profile Image': 'Update Profile Image',
    'Last Name*': 'Last Name*',
    'Address Line 1*': 'Address Line 1*',
    'Address Line 2': 'Address Line 2',
    'Address Line 3': 'Address Line 3',
    'Date Of Birth': 'Date Of Birth',
    Male: 'Male',
    Female: 'Female',
    Other: 'Other',
    Ethnicity: 'Ethnicity',
    Race: 'Race',
    Select: 'Select',
    Hinduism: 'Hinduism',
    Islam: 'Islam',
    Buddhism: 'Buddhism',
    Christianity: 'Christianity',
    Other: 'Other',
    Income: 'Income',
    'below 1 lakh': 'below 1 lakh',
    '2 lakh': '2 lakh',
    '3 lakh': '3 lakh',
    '4 lakh': '4 lakh',
    'above 5 lakh': 'above 5 lakh',
    'Marital Status': 'Marital Status',
    Single: 'Single',
    Married: 'Married',
    Widowed: 'Widowed',
    Separated: 'Separated',
    Divorced: 'Divorced',
    Town: 'Town',
    'Email*': 'Email*',
    'Zip Code*': 'Zip Code*',
    'Page List': 'Page List',
    Title: 'Title',
    'Page Name*': 'Page Name*',
    'Title*': 'Title*',
    'Content*': 'Content*',
    'Meta Keywords': 'Meta Keywords',
    'Meta Description': 'Meta Description',
    Save: 'Save',
    Back: 'Back',
    'Name is a required field': 'Name is a required field',
    'Email is a required field': 'Email is a required field',
    'Invalid email': 'Invalid email',
    'Invalid email': 'Invalid email',
    'Address is a required field': 'Address is a required field',
    'Description is arequired field': 'Description is arequired field',
    'Invalid phone number': 'Invalid phone number',
    'Phone number is a required field': 'Phone number is a required field',
    'first name is a required field': 'first name is a required field',
    'last name is a required field': 'last name is a required field',
    'email is a required field': 'email is a required field',
    'address 1 is a required field': 'address 1 is a required field',
    'country is a required field': 'country is a required field',
    'state is a required field': 'state is a required field',
    'city is a required field': 'city is a required field',
    'zipcode is a required field': 'zipcode is a required field',
    'phone number is a required field': 'phone number is a required field',
    'invalid phone number': 'invalid phone number',
    'Oops, something went wrong not able to fetch user details':
      'Oops, something went wrong not able to fetch user details',
    'Oops, something went wrong not able to fetch course.': 'Oops, something went wrong not able to fetch course.',
    'Name is a required field': 'Name is a required field',
    'Content is a required field': 'Content is a required field',
    Required: 'Required',
    'Passwords must match': 'Passwords must match',
    'Too Long': 'Too Long',
    'Too short': 'Too short',
    'Join event': 'Join event',
    'password is a required field': 'password is a required field',
    'password must match': 'password must match',
    'Your password is successfully updated, please log in with new password.':
      'Your password is successfully updated, please log in with new password.',
    'Your link is expired.': 'Your link is expired.',
    "You should soon receive an email allowing you to reset your password.Please make sure to check your spam and trash if you can't find the email.":
      "You should soon receive an email allowing you to reset your password.Please make sure to check your spam and trash if you can't find the email.",
    'Your link is expired.': 'Your link is expired.',
    'Read More': 'Read More',
    Enrol: 'Enrol',
    'Advance Courses For All': 'Advance Courses For All',
    'Course Enrolled successfully': 'You have successfully enrolled for this course',
    'Oops, something went wrong not able to fetch Course  details.':
      'Oops, something went wrong not able to fetch Course  details.',
    'Oops, something went wrong not able to fetch Announcement  details.':
      'Oops, something went wrong not able to fetch Announcement  details.',
    'Oops, something went wrong not able to fetch blog  details.':
      'Oops, something went wrong not able to fetch blog  details.',
    'Opps, Something went wrong not able to fetch Course  details.':
      'Opps, Something went wrong not able to fetch Course  details.',

    // New
    Dashboard: 'Dashboard',
    'Are you sure to delete your account': 'Are you sure to delete your account',
    'Expires On :': 'Expires On :',
    'Expired Courses List': 'Expired Courses List',
    'Feedback submitted successfully ': 'Feedback submitted successfully ',
    'Feedback submitted successfully ': 'Feedback submitted successfully ',
    'Successfully submitted': 'Successfully submitted',
    'Something went wrong, try again': 'Something went wrong, try again',
    'Active Courses': 'Active Courses',
    'View Details': 'View Details',
    'Expired Courses': 'Expired Courses',
    'You are allowed to login only if you are a registered user either as admin or as teacher.':
      'You are allowed to login only if you are a registered user either as admin or as teacher.',
    'Message List': 'Message List',
    'Active Courses List': 'Active Courses List',
    'view Result': 'view Result',
    NotifyMe: 'Notify Me',
  },

  indonesia: {
    Home: 'Rumah',
    Courses: 'Kursus',
    'About Us': 'Tentang Kita',
    Contact: 'Kontak',
    'Contact Us': 'Kontak Kita',
    'News Announcements And Events': 'Pengumuman dan Acara Berita',
    News: 'Berita',
    Welcome: 'Selamat Datang',
    Login: 'Gabung',
    'Log In': 'Gabung',
    'Sign Up': 'Daftar',
    Blogs: 'Blog',
    Email: 'Surel',
    'The webinar has been full': 'Webinar sudah penuh',
    'Event not yet started': 'Acara belum dimulai',
    Address: 'Alamat',
    'Copyright 2019. All Rights Reserved by': 'Hak cipta 2019. Semua hak dilindungi undang-undang',
    'Disclaimers & Copyright': 'Disclaimer and Hak cipta',
    'Terms And Condition': 'Syarat dan Ketentuan',
    FAQs: 'FAQs',
    Author: 'Penulis',
    'Course Duration': 'Durasi Kursus',
    'List Of Course Sections': 'Daftar Bagian Kursus',
    'Privacy Policy': 'Rahasia Pribadi',
    Office: 'Kantor',
    'No Record Found': 'Tidak Ada Catatan Yang Ditemukan',
    'Courses Category': 'Kategori Kursus',
    'Please send us a message and we will get back to you soon.':
      'Silakan kirim pesan kepada kami dan kami akan segera menghubungi Anda',
    Announcements: 'Pengumuman',
    Events: 'Acara',
    'Error 404': 'Kesalahan 404',
    'Unfortunately site content is not avilable in your country. Please wait till the time services get started in your region.':
      'Sayangnya konten situs tidak tersedia di negara Anda. Harap tunggu sampai layanan dimulai di wilayah Anda',
    'Frequently asked questions': 'Pertanyaan Yang Sering Diajukan',
    Links: 'Tautan',
    'Forgot Password': 'Tidak Ingat Kata Sandi',
    'You should soon receive an email allowing you to reset your password':
      'Anda akan segera menerima email yang memungkinkan Anda untuk mengatur ulang kata sandi Anda',
    "Please make sure to check your spam and trash if you can't find the email":
      'Harap pastikan untuk memeriksa spam dan sampah jika Anda tidak dapat menemukan email tersebut',
    'Forgot Password!': 'Tidak Ingat Kata Sandi!',
    'Forgot password?': 'Tidak Ingat Kata Sandi?',
    'Reset Password': 'Setel Ulang Kata Sandi',
    'login to your registered account!': 'Masuk Ke Daftar Akun Anda',
    'Remember Me': 'Ingat Saya',
    Password: 'Kata Sandi',
    'Not a member yet?': 'Belum Menjadi Anggota?',
    'Register now': 'Daftar Sekarang',
    'New Password': 'Kata Sandi Baru',
    'Confirm Password': 'Setujui Kata Sandi',
    submit: 'Kirimkan',
    Login: 'Gabung',
    'Log Out': 'Keluar',
    'Register a new account': 'Mendaftarkan Akun Baru',
    'Title*': 'Judul*',
    'First Name*': 'Nama Depan*',
    'Last Name*': 'Nama Keluarga*',
    'Name*': 'Nama*',
    'City*': 'Kota*',
    City: 'Kota',
    'Address*': 'Alamat*',
    'Address Line 1*': 'Barisan Alamat 1*',
    'Address 2': 'Alamat 2',
    'Address 3': 'Alamat 3',
    'ZipCode*': 'Kode Pos*',
    'State*': 'Negara*',
    'Country*': 'Negara*',
    'Date Of Birth': 'Tanggal Lahir',
    Gender: 'Jenis Kelamin',
    Race: 'Ras',
    Religion: 'Agama',
    Income: 'Pendapatan',
    'Marital Status': 'Situs Pekawinan',
    Education: 'Pendidikan',
    Industry: 'Industri',
    Employment: 'Pekerjaan',
    Ethnicity: 'Etnis',
    'Phone*': 'Telepon*',
    'Password*': 'Kata Sandi*',
    'Confirm Password*': 'Setujui Kata Sandi*',
    'Receive Personalised': 'Terima Dipersonalisasi',
    'Receive NewsLetter': 'Menerima Surat Kabar',
    'Are you a member?': 'Apakah Kamu Anggota?',
    'Login now': 'Masuk Sekarang',
    'Sign up to the Publishing Clinic newsletter to receive the latest news and updates':
      'Daftar ke buletin kami untuk menerima berita dan pembaruan terbaru',
    'Enter Name': 'Masukkan Nama',
    'Enter Email Address': 'Masukan Alamat Email',
    Name: 'Nama',
    'Order Date:': 'Tanggal Pemesanan:',
    'Order Id:': 'Id Pemesanan:',
    'Course Name:': 'Nama Kursus:',
    'Base Price': 'Harga dasar',
    'Tax Rate': 'Persentase Pajak',
    'Tax Type': 'Jenis Pajak',
    'Tax Value': 'Nilai Pajak',
    'Discount Value': 'Nilai Diskon',
    'Final Price': 'Harga Akhir',
    'Total:': 'Total:',
    CouponCode: 'Kode Kupon',
    'Apply Coupon': 'Menerapkan Kupon',
    CheckOut: 'Periksa',
    'Pay Rs.': 'Bayar ',
    'Popular Courses': 'Kursus Populer',
    'Discourse assurance estimable applauded to so. Him everything melancholy uncommonly but solicitude inhabiting projection off. Connection stimulated estimating excellence an to impression.':
      'Discourse assurance estimable applauded to so. Him everything melancholy uncommonly but solicitude inhabiting projection off. Connection stimulated estimating excellence an to impression.',
    'Course Enrolled Successfull': 'Anda telah berhasil mendaftar untuk kursus ini',
    'Thanks, we will notify you once course is available.':
      'Terima kasih, kami akan memberi tahu Anda setelah kursus tersedia',
    'Will you like to get notified once course is available.':
      'Apakah Anda ingin mendapatkan pemberitahuan setelah kursus tersedia?',

    'Enter course name': 'Masukkan Nama Kursus',
    'Search Courses': 'Kursus Pencarian',

    "Driver's list": 'Daftar Pengemudi',
    'Name/Company': 'Nama/Perusahaan',
    Close: 'Menutup',
    'Students Feedback': 'Umpan Balik Siswa',

    'Call Us': 'Hubungi Kami',
    'Email Us': 'Email Kami',
    'Our Location': 'Lokasi Kami',
    'Phone number*': 'Nomer Telepon*',
    'Please enter your message here*': 'Silahkan masukkan pesan anda disini*',
    'Send Message': 'Kirim pesan',
    'Correct Answer:': 'Jawaban yang benar:',
    Question: 'Pertanyaan',
    Start: 'Memulai',
    'Total time left:': 'Total waktu tersisa:',
    of: 'Dari atau penembahan kata',
    Preview: 'Peninjauan',
    'Quiz Submitted': 'Kuis dikirim',
    'Show preview': 'Tamplikan pratinjau',
    'Retry Again?': 'Coba lagi?',
    'Result:': 'Hasil:',
    'You got': 'Kamu punya',
    'out of': 'Dari luar',
    in: 'Di',
    test: 'Uji',
    'Confirm Reset?': 'Konfirmasi reset?',
    'Are you sure want to retry?': 'Apaka kamu yakin ingin mencoba lagi?',
    'it will erase all your previous result.': 'Itu akan menghapus semua hasil sebelumnya.',
    'Show preview': 'Tamplikan pratinjau',
    'Retry Again?': 'Coba lagi?',
    'Confirm Reset?': 'Konfirmasi reset?',
    Yes: 'Ya',
    No: 'Tidak',
    'Final result will be displayed here once teacher will review all your answer':
      'Hasil akhir akan ditampilkan di sini setelah guru meninjau semua jawaban anda',
    'Download Certificate': 'Unduh sertifikat',
    'Quiz Name': 'Nama kuis',
    'Marks Obtained': 'Tanda diperoleh',
    Result: 'Hasil',
    'Thanks for choosing our course and hope you found it beneficial. Please spend a few minutes to gives us your thoughts about the course and any comments and suggestions. Thanks again for your time.':
      'Terima kasih telah memili kursus kami dan semoga bermanfaat. Mohon luangkan beberapa menit. untuk memberi kami pemikiran anda tentang kursus serta comentar dan saran apa pun.',
    Select: 'Di pilih',
    Poor: 'Miskin',
    Unsatisfactory: 'Tidak memuaskan',
    Acceptable: 'Dapat diterima',
    Satisfactory: 'Memuaskan',
    Excellent: 'Luar biasa',
    Save: 'Menyimpan',
    'Thanks for your time and Best of Luck.': 'Terima kasih atas waktu anda dan semoga sukses.',
    'Your browser does not support HTML video.': 'Browser anda tidak mendukung video HTML.',
    'Add testimonial': 'Tambahkan testimonial',
    Feedback: 'Umpan balik',
    'Session taken on:': 'Sesi diambil:',
    Watch: 'Menonton',
    'Events Details': 'Detail acara',
    'Start Date': "Tanggal permula'an",
    'End Date': 'Tanggal akhir',
    'Filter Teacher': 'Guru ahli',
    Filter: 'Di saring',
    'Reset Filters': 'Setel ulang yang di saring',
    "Live Session Events Detail's": 'Detail acara sesi langsung',
    Event: 'Peristiwa',
    'Teacher Name': 'Nama guru',
    Date: 'Tanggal',
    Status: 'Status',
    'No Event Found': 'Tidak ada acara yang ditemukan',
    'Event List': 'Daftar acara',
    'Course List': 'Daftar kursus',
    You: 'Kamu',
    'Ask your Query...': 'Tanyakan pertanyaan anda...',
    'Type a message...': 'Mengetik pesan...',
    Send: 'Kirim',
    'Course Name': 'Nama kursus',
    'Choose Password': 'Pilih kata sandi',
    'Retype Password': 'Ketik ulang kata sandi',
    'Old Password': 'Kata sandi lama',
    Update: 'Memperbarui',
    Summary: 'Ringkasan',
    'Testimonial Details': 'Detail testimonial',
    Submit: 'Kirimkan',
    'Live session start daily at :': 'Sesi langsung di mulai setiap harinya :',
    'End Date': 'Tanggal akhir',
    'End Time': 'Akhir waktu',
    'Live session start weekely on :': 'Sesi langsung di mulai setiap minggu :',
    'Live session start on these following dates:': 'Sesi live di mulai pada tanggal-tanggal berikut ini:',
    loading: 'Memuat',
    'loading...': 'Memuat...',
    'Add testimonial': 'Tambahkan testimonial',
    Download: 'Mengunduh',
    Feedback: 'Umpan balik',
    'Add testimonial': 'Tambahkan testimonial',
    'Your Course Will Be Avaliable Soon.': 'Kursus anda akan segera tersedia.',
    download: 'Mengunduh',
    Score: 'Nilai',
    'Expire On': 'Kadaluarsa',
    'No courses linked to your account': 'Tidak ada kursus yang ditautkan ke akun anda',
    'please click here to redirect': 'Silakan klik di sini untuk mengalihkan',
    'please click here to redirect to course page': 'Silakan klik di sini untuk mengarahkan ke halaman kursus',
    Orders: 'Pesanan',
    'Edit Profile': 'Sunting profil',
    'My Courses': 'Kursus saya',
    Notifications: 'Pemberitahuan',
    Messages: 'Pesanan',
    Message: 'Pesan',
    'Live session details': 'Detail sesi langsung',
    'Live Session Details': 'Detail Sesi Langsung',
    phone: 'Telepon',
    'Delete Account': 'Hapus akun',
    Order: 'Memesan',
    'Course Name': 'Nama kursus',
    'Purchase Date': 'Tanggal pembelian',
    Price: 'Harga',
    Invoice: 'Faktur',
    'No order placed till now  please enrol yourself to the course.':
      'Hingga saat ini belum ada pesana dibuat, harap masukkan diri anda kursus.',
    'Change Password': 'Ganti kata sandi',
    'Title*': 'Judul*',
    Dr: 'Dokter',
    Mr: 'Tuan',
    Mrs: 'Nyonya',
    Miss: 'Rindu',
    Ms: 'Nona',
    'Update Profile Image': 'Perbarui gambar profil',
    'Last Name*': 'Nama keluarga*',
    'Address Line 1*': 'Baris alamat 1*',
    'Address Line 2': 'Baris alamat 2',
    'Address Line 3': 'Baris alamat 3',
    'Date Of Birth': 'Tanggal lahir',
    Male: 'Pria',
    Female: 'Perempuan',
    Other: 'Lain',
    Ethnicity: 'Etnis',
    Race: 'Ras',
    Select: 'Pilih',
    Hinduism: 'Hindusime',
    Islam: 'Muslim',
    Buddhism: 'Agama Budha',
    Christianity: 'Kristen',
    Other: 'Lain',
    Income: 'Pendapatan',
    'below 1 lakh': 'Di bawah 1 lakh',
    '2 lakh': '2 lakh',
    '3 lakh': '3 lakh',
    '4 lakh': '4 lakh',
    'above 5 lakh': 'Di atas 5 lakh',
    'Marital Status': 'Status pernikahan',
    Single: 'Single/jomblo/sendiri',
    Married: 'Menikah',
    Widowed: 'Janda',
    Separated: 'Terpisah',
    Divorced: 'Bercerai',
    Town: 'Kota',
    'Email*': 'Email*',
    'Zip Code*': 'Kode Pos*',
    'Page List': 'Daftar halaman',
    Title: 'Judul',
    'Page Name*': 'Nama halaman*',
    'Title*': 'Judul*',
    'Content*': 'Konten / Kandungan*',
    'Meta Keywords': 'Kata kunci meta',
    'Meta Description': 'Deskripsi meta',
    Save: 'Menyimpan',
    Back: 'Kembali',
    'Name is a required field': 'Nama adalah bidang yang harus diisi',
    'Email is a required field': 'Email adalah bidang yang harus diisi',
    'Invalid email': 'Email tidak valid',
    'Address is a required field': 'Alamat adalah bidang yang harus diisi',
    'Description is arequired field': 'Deskripsi adalah bidang yang harus diisi',
    'Invalid phone number': 'Nomor telepon tidak valid',
    'Phone number is a required field': 'Nomor telepon harus diisi',
    'first name is a required field': 'Nama depan harus diisi',
    'last name is a required field': 'Nama belakang harus diisi',
    'email is a required field': 'Email harus diisi',
    'address 1 is a required field': 'Alamat 1 harus diisi',
    'country is a required field': 'Negara adalah bidang yang wajib diisi',
    'state is a required field': 'Negara adalah bidang yang wajib diisi',
    'city is a required field': 'Kota adalah bidang yang harus diisi',
    'zipcode is a required field': 'Kode Pos harus diisi',
    'phone number is a required field': 'Nomor telepon harus diisi',
    'invalid phone number': 'Nomor telepon tidak valid',
    'Oops, something went wrong not able to fetch user details':
      'Ups, ada yang salah tidak dapat mengambil detail pengguna',
    'Oops, something went wrong not able to fetch course.': 'Ups, ada yang salah tidak dapat mengambi kursus',
    'Name is a required field': 'Nama adalah bidang yang harus diisi',
    'Content is a required field': 'Konten adalah pertandingan yang harus diisi',
    Required: 'Yg dibutuhkan',
    'Passwords must match': 'Kata sandi harus cocok',
    'Too Long': 'Terlalu lama',
    'Too short': 'Terlalu singkat',
    'Join event': 'Gabung acara',
    'password is a required field': 'Kata sandi adalah bagian yang harus diisi',
    'password must match': 'Kata sandi harus cocok',
    'Your password is successfully updated, please log in with new password.':
      'Kata sandi Anda berhasil diperbarui, silakan masuk dengan kata sandi baru.',
    'Your link is expired.': 'Link anda sudah kadaluarsa.',
    "You should soon receive an email allowing you to reset your password.Please make sure to check your spam and trash if you can't find the email.":
      'Anda akan segera menerima email yang memungkinkan Anda untuk mengatur ulang kata sandi. Harap pastikan untuk memeriksa spam dan sampa jika Anda tidak dapat menemukan email tersebut.',
    'Your link is expired.': 'Link anda sudah kadaluarsa.',
    'Read More': 'Lebih merah',
    Enrol: 'Mendaftar',
    'Advance Courses For All': 'Kursus Lanjutan Untuk Semua',
    'Course Enrolled successfully': 'Kursus Berhasil',
    'Oops, something went wrong not able to fetch Course  details.':
      'Ups, ada yang salah karena tidak dapat mengambil detail kursus.',
    'Oops, something went wrong not able to fetch Announcement  details.':
      'Ups, ada yang tidak beres tidak dapat mengambil pengumuman.',
    'Oops, something went wrong not able to fetch blog  details.':
      'Ups, ada yang salah tidak dapat mengambil detail blog.',
    'Opps, Something went wrong not able to fetch Course  details.':
      'Ups, ada yang salah tidak dapat mengambil detail krusus.',

    'Are you sure to delete your account': 'Apakah Anda yakin akan menghapus akun Anda',
    'Expires On :': 'Kadaluarsa pada :',
    'Expired Courses List': 'Daftar Kursus Kedaluwarsa',
    'Feedback submitted successfully ': 'Umpan balik berhasil dikirim ',
    'Successfully submitted': 'Berhasil dikirim',
    'Something went wrong, try again': 'Ada yang salah coba lagi',
    'Active Courses': 'Kursus Aktif',
    'View Details': 'Melihat rincian',
    'Expired Courses': 'Kursus Kedaluwarsa',
    'You are allowed to login only if you are a registered user either as admin or as teacher.':
      'Anda diperbolehkan untuk masuk hanya jika Anda adalah pengguna terdaftar baik sebagai admin atau sebagai guru.',
    Dashboard: 'Dasbor',
    Course: 'kursus',
    'Message List': 'Daftar Pesan',
    Notification: 'Pemberitahuan',
    'Active Courses List': 'Daftar Kursus Aktif',
    'View Result': 'Lihat hasil',
    'view Result': 'Lihat hasil',
    'Notification List': 'Daftar Pemberitahuan',
    'Order List': 'Daftar Pesanan',
    NotifyMe: 'Beritahu saya',
  },
};
export default lang;
