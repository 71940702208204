import React from "react";
import { Link } from "react-router-dom";
import User from "../../services/userService";
import SettingLogo from "../../services/settingService";
import utils from '../../utils/util';
const language = localStorage.getItem("language");
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.userD = JSON.parse(localStorage.getItem("user"));
    this.settingServ = new SettingLogo();
    this.state = {
      logo: "",
    };
  }

  componentDidMount() {
    this.settingList();
  }

  setLang = (event) => {
    console.log(event.target.value)
    localStorage.setItem("language", event.target.value);
    window.location.reload();
  }

  async settingList() {
    try {
      let result = await this.settingServ.listSetting();
      result.data.forEach((logoUrl) => {
        if (logoUrl.name === "logo") {
          this.setState({ logo: logoUrl.value });
        }
      });
    } catch (err) {
      alert(utils.intlLang("Oops, something went wrong in setting"));
    }
  }

  render() {
    let data;
    if (this.props.updateUserData?.image) {
      data = (
        <Link to="/user/profile" style={{ padding: "29px" }}>
          <img
            src={this.props?.updateUserData?.image || "/assets/img/default_image.jpeg"}
            className="img-avatar"
            alt="profile image"
            height="50px"
            width="50px"
          />{" "}
          {/* Welcome <span>{this.props.updateUserData.first_name}</span> */}
          {utils.intlLang("Welcome")} <span>{this.props.updateUserData.first_name}</span>
        </Link>
      );
    } else if (window.user && window?.user.data?.image) {
      data = (
        <div className="">
          <Link
            to="/user/profile"
            className="navbar-brand"
            style={{ color: "#333333", marginTop: "6px", fontSize: "15px" }}
          >
            <img
              style={{ borderRadius: "50px" }}
              src={window?.user?.data?.image || "/assets/img/default_image.jpeg"}
              className="logo"
              alt="profile image"
              height="40px"
              width="40px"
            />{" "}
            {utils.intlLang("Welcome")} <span>{window.user.data.first_name}</span>
          </Link>
        </div>
      );
    } else {
      data = (
        <Link to="/user/profile">
          <img
            src="/assets/img/default_image.jpeg"
            className="img-avatar"
            alt="profile image"
            height="80px"
            width="80px"
          />{" "}
          {utils.intlLang("Welcome")}{" "}
          <span>
            {window.user && window.user.data && window.user.data.first_name ? window.user.data.first_name : " "}
          </span>
        </Link>
      );
    }
    let navLinks = [];
    if (this.props.isLogin) {
      navLinks = [
        <li key="2">
          <Link to="/logout">{utils.intlLang("Log Out")}</Link>
        </li>,
        <li key="5">{data}</li>,
      ];
    } else {
      navLinks = [
        <li key="3">
          <Link to="/login">{utils.intlLang("Log In")}</Link>
        </li>,
        // <li key="4">
        //   <Link to="/signup">{utils.intlLang("Sign Up")}</Link>
        // </li>,
      ];
    }
    return (
      <nav className="navbar navbar-default attr-border navbar-sticky bootsnav on no-full">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
              <i className="fa fa-bars"></i>
            </button>

            <Link className="" to="/">
              <img
                src={this.state.logo}
                className="logo"
                alt="Logo"
                height="80px"
                // width="80px"
                style={{ marginTop: "5px" }}
              />
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="navbar-menu">
            <ul className="nav navbar-nav navbar-right" data-in="#" data-out="#">
              {/* <li className="dropdown">
                <Link to="/"> {utils.intlLang('Home')}</Link>
              </li>
              <li className="dropdown">
                <Link to="/course"> {utils.intlLang('Courses')}</Link>
              </li> */}
              <li>
                <Link to="/pg/about_us"> {utils.intlLang('About Us')}</Link>
              </li>
              <li>
                <Link to="/contact">{utils.intlLang('Contact')}</Link>
              </li>
              {/* <li class="dropdown">

                <Link to="/blogs" class="dropdown-toggle active" data-toggle="dropdown" >
                  {utils.intlLang('News')}
                </Link>
                <ul class="dropdown-menu">
                  <li>
                    <Link to="/blogs">{utils.intlLang('News')}</Link>
                  </li>
                  <li>
                    <Link to="/events">{utils.intlLang('Events')}</Link>
                  </li>
                  <li>
                    <Link to="/announcements">{utils.intlLang('Announcements')}</Link>
                  </li>
                </ul>
              </li> */}
              {/* <li>
                <Link to="/blogs">{utils.intlLang('News')}</Link>
              </li> */}
              {navLinks}

              <li>
                <select
                  className="form-control"
                  style={{ marginTop: "25px" }}
                  onChange={this.setLang}
                  value={language === null ? 'english' : language}
                >
                  <option value="english">English</option>
                  <option value="indonesia">Indonesia</option>
                </select>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}