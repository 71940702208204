import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link } from 'react-router-dom';
import CourseService from '../../services/courseService';
import userServices from '../../services/userService';
import LikesCount from '../../services/likeService';
import Banner from '../Banner/banner';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactHtmlParser from 'react-html-parser';
import BannerService from '../../services/bannerService';
import Card from '../cardComponent/cardComponent';
import moment from 'moment';
import setting from '../../services/settingService';
import utils from '../../utils/util';
export default class Course extends React.Component {
  constructor(props) {
    super(props);
    this.category_id = props.match.params.id ? props.match.params.id : '';
    this.stud = window.user ? window.user.data._id : '';
    this.user = window.user;
    this.state = {
      category_id: this.category_id,
      courseList: [],
      likescount: [],
      categoryBasedList: [],
      successMessage: false,
      errorMessage: false,
      msg: '',
      settingList: [],
      loading: true,
    };
    this.search = {
      start: 0,
      perPage: 12,
      searchTxt: this.props.match.params.searchTxt ? this.props.match.params.searchTxt : '',
      searchField: 'name',
    };

    this.bannerServ = new BannerService();
    this.courseServ = new CourseService();
    this.userserv = new userServices();
    this.likesServ = new LikesCount();
    this.settingserv = new setting();

    this.userCurrentCourse = [];
  }
  componentDidMount() {
    window.scroll(0, 0);
    this.getCategoryBasedCourse();
    this.getBannerList();
    this.getCourseList();
    this.countLikes();
    this.listAllLikes();
    this.getSettingVal();
    if (this.stud) {
      this.getStudent();
    }
  }

  componentWillReceiveProps(newProps) {
    this.search.searchTxt = newProps.match.params.searchTxt ? newProps.match.params.searchTxt : '';
    this.getCourseList();
  }

  componentDidUpdate(prvProps, prvState) {
    if (prvProps != this.props) {
      this.setState(
        {
          category_id: this.props.match.params.id ? this.props.match.params.id : '',
        },
        () => {
          this.getCategoryBasedCourse();
        }
      );
    }
  }

  getSettingVal() {
    this.settingserv.listSetting(0, 10000000).then(
      (response) => {
        this.setState({ settingList: response.data });
      },
      (error) => {
        this.setState({ settingList: [] });
      }
    );
  }
  getStudent() {
    this.userserv.getUser(this.stud).then(
      (response) => {
        this.stud = response.data;
      },
      (error) => {
        alert(utils.intlLang('Something went wrong not able to fetch course  details.'));
      }
    );
  }

  PlacedOrderZeroPrice(crs) {
    let { _id, is_active, period, available_seats } = crs;

    if (!available_seats) {
      if (window.confirm(utils.intlLang('Will you like to get notified once course is available.'))) {
        debugger;
        this.userserv.notifyme({ userId: this.stud._id, courseId: _id }).then(
          (response) => {
            if (response.data._id) {
              toast.success(utils.intlLang('Thanks, we will notify you once course is available.'));
            }
          },
          (error) => {
            this.setState({ courseList: [], loading: false });
          }
        );
      }
    } else {
      let day = moment().add(period, 'days').valueOf();
      let courses = [...this.stud.courses, { course: _id, is_active, end_time: day, is_course_completed: false }];

      this.stud.courses = courses;
      this.stud.selectedCourseId = _id;
      this.userserv.editUser(this.stud).then(
        (response) => {
          window.scroll(50, 50);
          toast.success(utils.intlLang('You have successfully enrolled for this course'));
          this.setState({ successMessage: true, errorMessage: false });
        },
        (error) => {
          if (error) {
            toast.error(error.message.replace('Error:', ''));
          }
          this.setState({
            successMessage: false,
            errorMessage: true,
            msg: error.message ? error.message.replace('Error:', '') : 'Something went wrong',
          });
        }
      );
    }
  }

  getBannerList() {
    this.bannerServ
      .listBanner(this.search.start, this.search.perPage, this.search.searchTxt, this.search.searchField)
      .then(
        (response) => {
          this.bannerList = [];
          for (let i = 0; i < response.data.length; i++) {
            this.bannerList.push(response.data[i]);
          }

          this.setState({ bannerList: response.data });
        },
        (error) => {
          this.setState({ bannerList: [] });
        }
      );
  }

  getCourseList() {
    this.setState({ loading: true });
    this.courseServ.listCourse(0, 1000, this.search.searchTxt, this.search.searchField).then(
      (response) => {
        response.data.description = ReactHtmlParser(response.data.description);
        this.setState({ courseList: response.data, loading: false });
      },
      (error) => {
        this.setState({ courseList: [], loading: false });
      }
    );
  }

  getCategoryBasedCourse() {
    if (this.state.category_id) {
      console.log(this.state.category_id, 'if category_id');
      this.courseServ.listCategoryBasedCourse(this.state.category_id).then(
        (response) => {
          this.setState({
            category_id: {},
            categoryBasedList: response.data,
          });
        },
        (error) => {
          this.setState({
            categoryBasedList: [],
          });
        }
      );
    } else {
      console.log(this.state.category_id, 'else- category_id');
      this.setState({ categoryBasedList: [] });
      this.getCourseList();
    }
  }
  countLikes(id) {
    this.likesServ.likesCount(id).then(
      (response) => {
        if (response.data === null) {
          response.data = [];
        } else {
          this.listAllLikes();
        }
      },
      (error) => {}
    );
  }

  listAllLikes() {
    this.likesServ.listLike().then(
      (response) => {
        this.setState({
          likescount: response.data,
        });
      },
      (error) => {
        this.setState({
          likescount: [],
        });
      }
    );
  }

  displayCourseList() {
    let data = [];
    let enroll = '';
    let detail = '';
    let userImage = '';
    let currencyVal = '';
    for (let i = 0; i < this.state.settingList.length; i++) {
      if (this.state.settingList[i].name === 'currencySymbol') {
        currencyVal = this.state.settingList[i].value;
      }
    }
    if (this.state.categoryBasedList.length > 0) {
      for (let i = 0; i < this.state.categoryBasedList.length; i++) {
        let count = 0;
        for (let j = 0; j < this.state.likescount.length; j++) {
          if (
            this.state.likescount[j].course === this.state.categoryBasedList[i]._id &&
            this.state.likescount[j].status === true
          ) {
            count = count + 1;
          }
        }
        if (
          this.state.categoryBasedList[i].is_active === true &&
          this.state.categoryBasedList[i].hide_public == false
        ) {
          if (this.user) {
            enroll = (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.PlacedOrderZeroPrice(this.state.categoryBasedList[i]);
                }}
                className='btn btn-theme effect btn-sm'
              >
                {' '}
                <i className='fas fa-chart-bar'></i>{' '}
                {this.state.categoryBasedList[i].available_seats ? utils.intlLang('Enrol') : utils.intlLang('NotifyMe')}
              </div>
            );
          } else if (!this.user) {
            enroll = (
              <Link className='btn btn-theme effect btn-sm' to={{ pathname: '/login/' }}>
                {' '}
                <i className='fas fa-chart-bar'></i>{' '}
                {this.state.categoryBasedList[i].available_seats ? utils.intlLang('Enrol') : utils.intlLang('NotifyMe')}
              </Link>
            );
          }

          if (!this.state.categoryBasedList[i].created_by || this.state.categoryBasedList[i].created_by.image == '') {
            userImage = <img src='/assets/img/team/2.jpg' alt='Thumb' />;
          } else {
            userImage = <img src={this.state.categoryBasedList[i].created_by.image} alt='Thumb' />;
          }

          if (this.state.categoryBasedList[i].image == '') {
            detail = <img className='imagepop' src='/assets/img/courses/6.jpg' alt='Thumb' />;
          } else {
            detail = <img className='imagepop' src={this.state.categoryBasedList[i].image} alt='Thumb' />;
          }
          if (this.state.categoryBasedList[i].description.length >= 49) {
            this.state.categoryBasedList[i].description_short =
              this.state.categoryBasedList[i].description.substring(0, 49) + '...';
          } else {
            this.state.categoryBasedList[i].description_short = this.state.categoryBasedList[i].description;
          }

          this.state.categoryBasedList[i]['name'] = this.state.categoryBasedList[i].name;

          data.push(
            <div key={'divcol-' + i} className='col'>
              <Link
                to={{
                  pathname: '/details/' + this.state.categoryBasedList[i]._id,
                }}
              >
                <div className='popular-courses-items bottom-price'>
                  <div className='col-md-4 col-sm-6 equal-height'>
                    <div className='item'>
                      <div className='thumb'>
                        {detail}

                        <div className='overlay'>{enroll}</div>
                      </div>
                      <div className='info'>
                        <div className='author-info'>
                          <Link
                            to={{
                              pathname: '/details/' + this.state.categoryBasedList[i]._id,
                            }}
                          >
                            <div className='thumb'>
                              <Link
                                to={{
                                  pathname: '/details/' + this.state.categoryBasedList[i]._id,
                                }}
                              >
                                {userImage}
                              </Link>
                            </div>
                          </Link>
                          <div className='others'>
                            <Link
                              to={{
                                pathname: '/details/' + this.state.categoryBasedList[i]._id,
                              }}
                            >
                              {this.state.categoryBasedList[i].created_by
                                ? this.state.categoryBasedList[i].created_by.first_name
                                : ''}
                            </Link>
                            <div className='rating'>
                              <span onClick={this.countLikes.bind(this, this.state.categoryBasedList[i]._id)}>
                                <i className='far fa-thumbs-up fa-2x'></i>
                              </span>

                              {count}
                              {/* <Link to={{ pathname: "/details/" + this.state.categoryBasedList[i]._id }}>
                              <div className="price-btn">
                                <>{currencyVal} </>
                                {this.state.categoryBasedList[i].price}
                              </div>
                            </Link> */}
                            </div>
                          </div>
                        </div>
                        <Link
                          to={{
                            pathname: '/details/' + this.state.categoryBasedList[i]._id,
                          }}
                        >
                          <h4>
                            <Link
                              to={{
                                pathname: '/details/' + this.state.categoryBasedList[i]._id,
                              }}
                            >
                              {this.state.categoryBasedList[i]['name']}
                            </Link>
                          </h4>
                          <OverlayTrigger
                            placement='top'
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                {ReactHtmlParser(this.state.categoryBasedList[i].description)}
                              </Tooltip>
                            )}
                            placement='bottom'
                          >
                            <div className='summary-descr'>
                              {ReactHtmlParser(this.state.categoryBasedList[i].description_short)}
                            </div>
                          </OverlayTrigger>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          );
        }
      }
    } else if (this.state.courseList.length === 0) {
      data.push(
        <div key={'norecord-'}>
          <h1 className='norecord'>{utils.intlLang('No Record Found')}.</h1>
        </div>
      );
    } else {
      for (let i = 0; i < this.state.courseList.length; i++) {
        let count = 0;
        for (let j = 0; j < this.state.likescount.length; j++) {
          if (
            this.state.likescount[j].course === this.state.courseList[i]._id &&
            this.state.likescount[j].status === true
          ) {
            count = count + 1;
          }
        }
        if (this.state.courseList[i].is_active === true && this.state.courseList[i].hide_public == false) {
          if (this.user) {
            enroll = (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.PlacedOrderZeroPrice(this.state.courseList[i]);
                }}
                className='btn btn-theme effect btn-sm'
              >
                {' '}
                <i className='fas fa-chart-bar'></i>{' '}
                {this.state.courseList[i].available_seats ? utils.intlLang('Enrol') : utils.intlLang('NotifyMe')}
              </div>
            );
          } else if (!this.user) {
            enroll = (
              <Link className='btn btn-theme effect btn-sm' to={{ pathname: '/login/' }}>
                {' '}
                <i className='fas fa-chart-bar'></i>{' '}
                {this.state.courseList[i].available_seats ? utils.intlLang('Enrol') : utils.intlLang('NotifyMe')}
              </Link>
            );
          }

          if (!this.state.courseList[i].created_by || this.state.courseList[i].created_by.image == '') {
            userImage = <img src='/assets/img/team/2.jpg' alt='Thumb' />;
          } else {
            userImage = <img src={this.state.courseList[i].created_by.image} alt='Thumb' />;
          }

          if (this.state.courseList[i].image == '') {
            detail = <img className='imagepop' src='/assets/img/courses/6.jpg' alt='Thumb' />;
          } else {
            detail = <img className='imagepop' src={this.state.courseList[i].image} alt='Thumb' />;
          }
          if (this.state.courseList[i].description.length >= 49) {
            this.state.courseList[i].description_short = this.state.courseList[i].description.substring(0, 49) + '...';
          } else {
            this.state.courseList[i].description_short = this.state.courseList[i].description;
          }

          this.state.courseList[i]['name'] = this.state.courseList[i].name;

          data.push(
            <div key={'divcol-' + i} className='col'>
              <Card
                id={this.state.courseList[i]._id}
                enroll={enroll}
                detail={detail}
                userImage={userImage}
                firstName={this.state.courseList[i].created_by ? this.state.courseList[i].created_by.first_name : ''}
                likescnt={count}
                coursePrice={this.state.courseList[i].price}
                courseName={this.state.courseList[i]['name']}
                description={this.state.courseList[i].description}
                description_short={this.state.courseList[i].description_short}
                countLikesFnc={this.countLikes.bind(this)}
              />
            </div>
          );
        }
      }
    }

    return data;
  }

  render() {
    let title = '';
    let pagename = 'CourseList';
    if (this.bannerList) {
      for (let i = 0; i < this.bannerList.length; i++) {
        if (this.bannerList[i].pagename === 'CourseList') {
          title = utils.intlLang(this.bannerList[i].title);
          pagename = this.bannerList[i].pagename;
        }
      }
    }
    return (
      <>
        <Banner pagename={pagename} pagelink={'/course'} title={title} showSearch={true} />
        <div className='popular-courses default-padding bottom-less without-carousel'>
          <div className='container'>
            {this.state.loading ? (
              <div style={{ textAlign: 'center' }}>
                <i className='fas fa-spinner fa-spin'></i> {utils.intlLang('loading...')}
              </div>
            ) : (
              this.displayCourseList()
            )}
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
}
