import React from 'react';
import Banner from '../Banner/banner';
import ContactService from '../../services/contactusService';
import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Setting from '../../services/settingService';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import BannerService from '../../services/bannerService';
import * as Yup from 'yup';
import utils from '../../utils/util';
import CountryCode from '../../components/countryCode.json';

export default class contactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { contact: '', redirect: false };
    this.state.contact = {
      name: '',
      email: '',
      address: '',
      description: '',
      phoneNumber: '',
      countryDialCode: '',
    };
    this.search = { start: 0, perPage: 12, searchTxt: '', searchField: '' };

    this.bannerServ = new BannerService();
    this.contactServ = new ContactService();
    this.settingServ = new Setting();
    const phoneNumberRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
    this.schema = Yup.object({
      name: Yup.string().required(utils.intlLang('Name is a required field')),
      email: Yup.string().required(utils.intlLang('Email is a required field')).email(utils.intlLang('Invalid email')),
      address: Yup.string().required(utils.intlLang('Address is a required field')),
      description: Yup.string().required(utils.intlLang('Description is arequired field')),
      phoneNumber: Yup.string()
        .matches(phoneNumberRegex, utils.intlLang('Invalid phone number'))
        .required(utils.intlLang('Phone number is a required field')),
      countryDialCode: Yup.string().required(utils.intlLang('Required')),
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getBannerList();
    this.settingfn();
  }

  settingfn() {
    this.settingServ.listSetting(0, 1000000).then(
      (response) => {
        this.setState({ settingList: response.data });
      },
      (error) => {
        this.setState({ settingList: [] });
      }
    );
  }

  getBannerList() {
    this.bannerServ
      .listBanner(this.search.start, this.search.perPage, this.search.searchTxt, this.search.searchField)
      .then(
        (response) => {
          this.bannerList = [];
          for (let i = 0; i < response.data.length; i++) {
            this.bannerList.push(response.data[i]);
          }

          this.setState({ bannerList: response.data });
        },
        (error) => {
          this.setState({ bannerList: [] });
        }
      );
  }

  submitForm(values, actions) {
    actions.setSubmitting(false);
    this.setState({
      contact: values,
    });

    let dial_Code = values.countryDialCode + ' ' + values.phoneNumber;
    let { name, email, address, description } = this.state.contact;
    let obj = {
      name: name,
      email: email,
      address: address,
      description: description,
      phoneNumber: dial_Code,
    };

    console.log(obj, 'Contact', dial_Code, 'c_code');
    this.contactServ.addContact(obj).then(
      (response) => {
        this.setState({ redirect: true });
      },
      (error) => {
        this.setState({ redirect: false });
      }
    );
  }

  render() {
    let country_dial_code = [];
    for (let i = 0; i < CountryCode.length; i++) {
      country_dial_code.push(
        <option key={CountryCode[i].code + ' ' + i} value={CountryCode[i].dial_code}>
          {CountryCode[i].dial_code} {CountryCode[i].name}
        </option>
      );
    }

    let email = '',
      officeaddr = '',
      contact = '',
      google_contact_url = '';

    if (this.state.redirect === true) {
      window.location.reload(false);
      return <Redirect to='/contact'></Redirect>;
    }

    let data = '';
    let pagename = '';
    if (this.bannerList) {
      for (let i = 0; i < this.bannerList.length; i++) {
        // if (this.bannerList[i].title === "Contact Us") {
        if (this.bannerList[i].pagename === 'Contact Us') {
          data = this.bannerList[i].title;
          pagename = this.bannerList[i].pagename;
        }
      }
    }
    if (this.state.settingList) {
      for (let i = 0; i < this.state.settingList.length; i++) {
        if (this.state.settingList[i].name === 'Email') {
          email = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === 'office address') {
          officeaddr = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === 'Contact') {
          contact = this.state.settingList[i].value;
        }
        if (this.state.settingList[i].name === 'google_contact_url') {
          google_contact_url = this.state.settingList[i].value;
        }
      }
    }
    if (this.state.redirect === true) {
      return <Redirect to='/contact' />;
    }
    return (
      <>
        <Banner pagename={pagename} pagelink={'/contactus'} title={data} showSearch={false} />
        <div className='contact-info-area default-padding'>
          <div className='container'>
            <div className='row'>
              <div className='contact-info'>
                <div className='col-md-4 col-sm-4'>
                  <div className='item'>
                    <div className='icon'>
                      <i className='fas fa-mobile-alt'></i>
                    </div>
                    <div className='info'>
                      <h4>{utils.intlLang('Call Us')}</h4>
                      <span>{contact}</span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 col-sm-4'>
                  <div className='item'>
                    <div className='icon'>
                      <i className='fas fa-map-marker-alt'></i>
                    </div>
                    <div className='info'>
                      <h4>{utils.intlLang('Address')}</h4>
                      <span dangerouslySetInnerHTML={{ __html: officeaddr }}></span>
                    </div>
                  </div>
                </div>
                <div className='col-md-4 col-sm-4'>
                  <div className='item'>
                    <div className='icon'>
                      <i className='fas fa-envelope'></i>
                    </div>
                    <div className='info'>
                      <h4>{utils.intlLang('Email Us')}</h4>
                      <span>{email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='seperator col-md-12'>
                <span className='border'></span>
              </div>
              <div className='maps-form'>
                <div className='col-md-6 maps'>
                  <h3>{utils.intlLang('Our Location')}</h3>
                  <div className='google-maps'>
                    <iframe src={google_contact_url} allowfullscreen='' loading='lazy'></iframe>
                  </div>
                </div>
                <div className='col-md-6 form'>
                  <div className='heading'>
                    <h3>{utils.intlLang('Contact Us')}</h3>
                    <p>{utils.intlLang('Please send us a message and we will get back to you soon.')}</p>
                  </div>
                  <Formik
                    validationSchema={this.schema}
                    initialValues={this.state.contact}
                    enableReinitialize={true}
                    onSubmit={this.submitForm.bind(this)}
                    render={({
                      values,
                      errors,
                      status,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <div className='address addresslist'>
                        <Container>
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type='text'
                                    placeholder={utils.intlLang('Email*')}
                                    value={values.email}
                                    name='email'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}
                                  />
                                  <ErrorMessage name='email'>
                                    {(msg) => <div className='err_below'>{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type='text'
                                    placeholder={utils.intlLang('Name*')}
                                    value={values.name}
                                    name='name'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.name && !errors.name}
                                  />
                                  <ErrorMessage name='name'>
                                    {(msg) => <div className='err_below'>{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type='text'
                                    placeholder={utils.intlLang('Address*')}
                                    value={values.address}
                                    name='address'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.address && !errors.address}
                                  />
                                  <ErrorMessage name='address'>
                                    {(msg) => <div className='err_below'>{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={1}>
                                <Form.Group style={{ width: '88px' }}>
                                  <Form.Control
                                    as='select'
                                    id='countryCode'
                                    name='countryDialCode'
                                    value={values.countryDialCode}
                                    onChange={handleChange}
                                    // disabled={is_deleted}
                                    style={{ height: '50px', border: '1px solid #e7e7e7', borderRadius: '0px' }}
                                  >
                                    <option>Select Country Code</option>
                                    {country_dial_code}
                                  </Form.Control>
                                  <ErrorMessage name='countryDialCode'>
                                    {(msg) => <div className='err_below'>{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                              <Col sm={9} md={5}>
                                <Form.Group>
                                  <Form.Control
                                    type='text'
                                    id='pNumber'
                                    placeholder={utils.intlLang('Phone number*')}
                                    value={values.phoneNumber}
                                    name='phoneNumber'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.phoneNumber && !errors.phoneNumber}
                                  />
                                  <ErrorMessage name='phoneNumber'>
                                    {(msg) => <div className='err_below'>{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={9} md={6}>
                                <Form.Group>
                                  <Form.Control
                                    type='text'
                                    placeholder={utils.intlLang('Please enter your message here*')}
                                    value={values.description}
                                    name='description'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.description && !errors.description}
                                  />
                                  <ErrorMessage name='description'>
                                    {(msg) => <div className='err_below'>{msg}</div>}
                                  </ErrorMessage>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <div className='col-md-12'>
                                <div className='row'>
                                  <button type='submit' name='submit' id='submit'>
                                    {utils.intlLang('Send Message')} <i className='fa fa-paper-plane'></i>
                                  </button>
                                </div>
                              </div>
                              <div className='col-md-12 alert-notification'>
                                <div id='message' className='alert-msg'></div>
                              </div>
                            </Row>
                          </Form>
                        </Container>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
