import React from 'react';
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe';
import Banner from '../Banner/banner';
import CourseService from '../../services/courseService';
import userServices from '../../services/userService';
import CourseCategoryService from '../../services/courseCategoryService';
import BannerService from '../../services/bannerService';
import ReactHtmlParser from 'react-html-parser';
import Testimonial from '../../services/testimonialService';
import Carousel from 'react-bootstrap/Carousel';
import { Helmet } from 'react-helmet';
import setting from '../../services/settingService';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import Quizze from './Quizze/Quizze';
import CourseQuestion from './CourseEvaluationQus.json';
import BackButton from '../../components/BackButton';
import utils from '../../utils/util';
export default class CourseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.course_id = props.match.params.id;
    this.user = window.user;
    console.log('props', props.match.params);
    this.ifrme = React.createRef();
    this.state = {
      isFullScreen: false,
      fullsize: false,
      exitfullsize: true,
      viewedLessonList: [] /*lesson viewed list*/,
      lessonChangeCounter: 0,
      course: {
        section: [
          {
            name: '',
            sequence: '',
            lesson: [
              {
                name: '',
                view_duration: '',
                file_path: '',
                lesson_type: '',
                available_for_preview: false,
              },
            ],
          },
        ],
      },
      successMessage: false,
      settingList: [],
      modal: false,
      file: {
        question: '',
        question_type: '',
        answer_type: '',
        file_path: '',
        Sequence: '',
        option: '',
      },
      sectionId: '',
    };
    this.search = { start: 0, perPage: 12, searchTxt: '', searchField: '' };

    this.bannerServ = new BannerService();
    this.courseServ = new CourseService();

    if (this.course_id) {
      this.courseServ.getCourse(this.course_id).then(
        (response) => {
          this.categoriesOption = [];
          let categoriesData = response.data;
          for (let i = 0; i < categoriesData.categories.length; i++) {
            this.categoriesOption.push(categoriesData.categories[i].id.name + '||');
          }
          this.setState({ course: response.data });
        },
        (error) => {
          alert(utils.intlLang('Oops, something went wrong not able to fetch Course  details.'));
        }
      );
    }
  }
  componentDidMount() {
    this.listViewedCourse();
  }

  handleShow() {
    this.setState({ modal: true });
  }
  handleClose() {
    this.setState({ modal: false });
  }
  handleViewedCourse(section, lesson) {
    let sectionId = section._id;
    let lessonId = lesson._id;
    let userId = this.props.match.params.userid;
    let courseId = this.course_id;
    this.courseServ
      .handleViewedCourse({ sectionId, lessonId, userId, courseId })
      .then((res) => {
        let viewedLesson = [...this.state.viewedLessonList];
        viewedLesson.push(res.data);
        this.setState({ viewedLessonList: viewedLesson });
      })
      .catch((err) => {});
  }
  listViewedCourse() {
    let userId = this.props.match.params.userid;
    let courseId = this.course_id;
    this.courseServ
      .ListViewedCourse({ userId, courseId })
      .then((res) => {
        this.setState({ viewedLessonList: res.data });
      })
      .catch((err) => {
        this.setState({ viewedLessonList: [] });
      });
  }

  toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.querySelector('#iframe-pdf').requestFullscreen();
      this.setState({ isFullScreen: true });
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.setState({ isFullScreen: false });
      }
    }
  };
  handleFullView = () => {
    this.setState({
      fullsize: true,
      exitfullsize: false,
    });
    var element = document.getElementById('fullsize');
    document.getElementById('myId').style.minHeight = '500px';
    element.classList.remove('col-md-8');
    element.classList.add('col-md-12');
  };
  handleexitFullView = () => {
    this.setState({
      fullsize: false,
      exitfullsize: true,
    });
    var element = document.getElementById('fullsize');
    document.getElementById('myId').style.minHeight = '350px';
    element.classList.remove('col-md-12');
    element.classList.add('col-md-8');
  };
  render() {
    let detail = null;
    if (this.state.course) {
      detail = (
        <div>
          <div style={{ marginTop: '30px' }}>
            <div className='container'>
              <div className='row'>
                <div className='col-md-8' id='fullsize'>
                  {this.state.file.lesson_type == 'quize' ? (
                    <Quizze lesson={this.state.file} sectionId={this.state.sectionId} courseId={this.course_id} />
                  ) : (
                    <>
                      <div id='iframe-pdf' ref={(ref) => (this.ifrme = ref)}>
                        {this.state.file.lesson_type == 'pdf' && (
                          <i
                            title='Full Screen'
                            onClick={this.toggleFullScreen}
                            className='fa fa-arrows-alt fullscreen'
                            aria-hidden='true'
                          ></i>
                        )}
                        {console.log(this.state, 'this.state')}
                        {this.state.file.lesson_type == 'video' ? (
                          <video width='100%' height={!this.state.isFullScreen ? '437px' : '1000px'} controls>
                            <source
                              src={this.state.file.file_path || this.state?.course.section[0]?.lesson[0]?.file_path}
                              type='video/mp4'
                            />
                            {/* .replace('http:','https:') */}
                            <source src='mov_bbb.ogg' type='video/ogg' />
                            {utils.intlLang('Your browser does not support HTML video.')}
                          </video>
                        ) : (
                          // .replace('http:','https:')

                          <Iframe
                            url={this.state.file.file_path || this.state?.course.section[0]?.lesson[0]?.file_path}
                            width='100%'
                            height={!this.state.isFullScreen ? '350px' : '1000px'}
                            id='myId'
                            className='myClassname'
                            display='initial'
                            position='relative'
                            allowFullScreen={true}
                            controls
                          />
                        )}
                      </div>
                      <div style={{ float: 'right', display: this.state.fullsize ? 'block' : 'none' }}>
                        <a style={{ cursor: 'pointer' }} onClick={this.handleexitFullView}>
                          {utils.intlLang('Exit Full View')}
                        </a>
                      </div>
                      <div style={{ float: 'right', display: this.state.exitfullsize ? 'block' : 'none' }}>
                        <a style={{ cursor: 'pointer' }} onClick={this.handleFullView}>
                          {utils.intlLang('Full View')}
                        </a>
                      </div>
                      <div style={{ clear: 'both' }}></div>
                    </>
                  )}
                </div>

                <div
                  className='col-md-4'
                  style={{
                    borderLeft: '1px solid #E0E0E0',
                    'margin-left': '15px',
                    width: '30%',
                    display: this.state.exitfullsize ? 'block' : 'none',
                  }}
                >
                  <h4
                    className='panel-title'
                    style={{ padding: '15px 0px 0px 0px', 'font-weight': '600', 'margin-bottom': '10px' }}
                  >
                    {utils.intlLang('List Of Course Section')}
                  </h4>
                  <hr />
                  <div className='sidebar'>
                    <aside>
                      <div className='tab-info'>
                        <div className='tab-content tab-content-info' style={{ marginTop: '12px' }}>
                          {/* <!-- Single Tab --> */}
                          <div id='tab2' className='tab-pane fade active in'>
                            <div className='info title'>
                              {/* <!-- Start Course List --> */}
                              {this.state.course.section &&
                                this.state.course.section.map((el, i) => {
                                  if (!el.is_disabled) {
                                    return (
                                      <div className='course-list-items acd-items acd-arrow'>
                                        <div className='panel-group symb' id='accordion'>
                                          <div className='panel panel-default'>
                                            <div className='panel-heading'>
                                              <h4 className='panel-title'>
                                                <a
                                                  data-toggle='collapse'
                                                  data-parent='#accordion'
                                                  href={`#${i}`}
                                                  aria-expanded='false'
                                                  className='collapsed'
                                                >
                                                  {el.name}
                                                </a>
                                              </h4>
                                            </div>
                                            <div
                                              id={i}
                                              className='panel-collapse collapse'
                                              aria-expanded='false'
                                              style={{ height: ' 0px' }}
                                            >
                                              <div className='panel-body'>
                                                <ul>
                                                  {el.lesson.map((el2, i2) => {
                                                    if (!el2.is_disabled) {
                                                      return (
                                                        <li
                                                          key={el2._id}
                                                          style={{ cursor: 'pointer' }}
                                                          onClick={() => {
                                                            this.handleViewedCourse(el, el2);
                                                            this.setState({
                                                              file: el2,
                                                              modal: true,
                                                              sectionId: el._id,
                                                              lessonChangeCounter: this.state.lessonChangeCounter + 1,
                                                            });
                                                          }}
                                                        >
                                                          <div className='row' style={{ width: '100%' }}>
                                                            <div className='item title col-xs-7'>
                                                              <h5>{el2.name}</h5>
                                                            </div>
                                                            <div className='item info col-xs-5'>
                                                              <span>
                                                                {el2.lesson_type == 'video' ? (
                                                                  <i className='fas fa-video'></i>
                                                                ) : (
                                                                  <i className='fas fa-file-alt'></i>
                                                                )}{' '}
                                                                {el2.view_duration}
                                                              </span>
                                                              <span>
                                                                {this.state.viewedLessonList.map((vl) => {
                                                                  if (vl.lessonId == el2._id) {
                                                                    return (
                                                                      <i
                                                                        style={{ color: 'green' }}
                                                                        className='fas fa-check-circle'
                                                                      ></i>
                                                                    );
                                                                  }
                                                                })}
                                                              </span>
                                                              <span>
                                                                {' '}
                                                                {el2.lesson_type == 'uploaddoc' ? (
                                                                  <a target='_blank' href={el2.file_path}>
                                                                    <i className='fas fa-download'></i>
                                                                  </a>
                                                                ) : (
                                                                  ''
                                                                )}
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      );
                                                    }
                                                  })}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              {/* <!-- End Course List --> */}
                            </div>
                          </div>

                          {/* <!-- End Single Tab --> */}
                        </div>
                        {/* <!-- End Tab Content --> */}
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>{this.state.course.name}</title>
          <meta name='keywords' content={this.state.course.meta_keywords} />
          <meta name='description' content={this.state.course.meta_description} />
        </Helmet>
        {detail}
      </>
    );
  }
}
