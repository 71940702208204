import util from '../utils/util';

export default class SettingService {
  // listSetting(start,length,searchTxt,searchField) {
  listSetting(start, length) {
    if (window.SettingListResponse) {
      return new Promise((resolve, reject) => {
        resolve(window.SettingListResponse);
      });
    }
    let url = '/setting/list/' + start + '/' + length;
    // if(searchTxt && searchField){
    //  url = "/setting/list/"+start+"/"+ length+"/"+searchTxt+"/"+searchField.toLowerCase();
    // }

    return util.sendApiRequest(url, 'GET', true).then(
      (response) => {
        window.SettingListResponse = response;
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
